<template>
  <div>
    <eden-table-actions :title="title" @search="setQuery">
      <template slot="title">
        <service-operations-filter
          class="ml-10"
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
      </template>
      <template slot="actions">
        <template
          v-if="selectedCombos.length && showComboActions"
          class="fadeIn"
        >
          <el-switch
            v-model="markAsPlated"
            :disabled="!markableForPlating"
            active-text="Mark as plated"
            :active-value="true"
            inactive-text=""
          >
          </el-switch>
          <el-switch
            v-model="markAsSealed"
            :disabled="!markableForSealing"
            active-text="Mark as sealed"
            :active-value="true"
            inactive-text=""
            class="ml-10"
          >
          </el-switch>
          <el-switch
            v-model="markAsSleeved"
            :disabled="!markableForSleeving"
            active-text="Mark as sleeved"
            :active-value="true"
            inactive-text=""
            class="ml-10"
          >
          </el-switch>
        </template>
        <template v-else>
          <stove-off :period="period" :disabled="loading || !combos.length" />
          <el-button
            type="plain"
            icon="eden-icon-upload"
            class="ml-10"
            :disabled="loading || !combos.length"
            @click="exporting = true"
          >
            Export
          </el-button>
          <el-button type="plain" :disabled="loading" @click="viewHistory"
            >View history</el-button
          >
        </template>
      </template>
    </eden-table-actions>

    <p v-if="loading">Loading</p>
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <combo-check-items-table
        :period="period"
        :combos="combos"
        :allow-marking="allowMarking"
        :allow-status-show="showStatus"
        @set-selected-combos="setSelectedCombos"
        @set-selected-combo="setSelectedCombo"
      />
    </template>
    <mark-combos
      ref="markCombos"
      :show.sync="showMarkCombos"
      :service-partner-id="servicePartnerId"
      :mark-function="markFunction"
      :combos.sync="selectedCombos"
      @mark="updateCombos"
      @unmark="unmarkCombos"
    />
    <combo-check-export
      :show.sync="exporting"
      :period="period"
      :custom="custom"
      :data="combos"
      :data-time="pageDataTime"
    />
  </div>
</template>

<script>
import { formatItems } from "../format-items";
import * as actions from "@/store/action-types";
import combocheck from "@/requests/stewards/combocheck";
import ServiceOperationsFilter from "@/components/ServiceOperations/ServiceOperationsFilter";
import MarkCombos from "@/components/Stewards/ComboCheck/MarkCombos";
import ComboCheckExport from "./Export/ComboCheckExport.vue";
import ComboCheckItemsTable from "@/components/Stewards/ComboCheck/ComboCheckItemsTable";
import StoveOff from "@/components/Stewards/ComboCheck/StoveOff";

export default {
  name: "ComboCheckItems",
  props: {
    allowMarking: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StoveOff,
    ComboCheckItemsTable,
    MarkCombos,
    ServiceOperationsFilter,
    ComboCheckExport,
  },
  data() {
    return {
      loading: false,
      searchQuery: "",
      period: "today",
      periods: {
        today: "Today",
        tomorrow: "Tomorrow",
        thisweek: "This week",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      custom: {
        from: null,
        to: null,
      },
      pageData: [],
      pageDataTime: "",
      selectedCombos: [],
      showComboActions: false,
      markAsPlated: false,
      markableForPlating: true,
      markAsSealed: false,
      markableForSealing: true,
      markAsSleeved: false,
      markableForSleeving: true,
      markFunction: "",
      showMarkCombos: false,
      exporting: false,
    };
  },
  computed: {
    servicePartnerId() {
      return this.getDefaultSp("meal");
    },
    title() {
      return `${this.combos.length} Combos`;
    },
    combos() {
      const query = this.formatQueryText(this.searchQuery);

      if (query !== "") {
        this.mixpanelTrack({
          event: "combo_check_export_search",
        });
        return this.pageData.filter((combo) => {
          return combo.name.toLowerCase().includes(query);
        });
      } else {
        return this.pageData;
      }
    },
    showStatus() {
      if (this.period.includes("week")) {
        return false;
      } else if (this.period === "custom") {
        return !!(this.custom.from && this.custom.from === this.custom.to);
      } else {
        return true;
      }
    },
    userId() {
      return this.$store.getters.user.logged_in_userId;
    },
  },
  watch: {
    markAsPlated() {
      if (this.markAsPlated) {
        this.markFunction = "plated";
        this.showMarkCombos = true;
      }
    },
    markAsSealed() {
      if (this.markAsSealed) {
        this.markFunction = "sealed";
        this.showMarkCombos = true;
      }
    },
    markAsSleeved() {
      if (this.markAsSleeved) {
        this.markFunction = "sleeved";
        this.showMarkCombos = true;
      }
    },
  },
  created() {
    this.getCombos({ period: this.period });
  },
  methods: {
    filter({ params, paramsLabel }) {
      this.loading = true;
      let stringedParams = `?${this.sortObjectAsParams(params)}`;
      if (!stringedParams.includes("from")) {
        stringedParams = `?${this.sortObjectAsParams(params)}&period=${
          this.period
        }`;
      }
      if (stringedParams.includes("from")) {
        this.custom.from = params.from;
        this.custom.to = params.to;
      }
      if (this.sortObjectAsParams(params).includes("same-day-delivery")) {
        stringedParams = `?filter=one-time&period=today`;
      }
      combocheck
        .filterCombo(this.servicePartnerId, stringedParams)
        .then((response) => {
          const { status } = response;
          if (status) {
            this.filterParams = {
              status: true,
              params: params,
              paramsLabel: paramsLabel,
            };
            const combo = response.data.data;
            this.pageData = this.sortList(formatItems(combo, "combo"), "name");
            this.pageDataTime = new Date();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    getCombos({ period, from, to }) {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_COMBO_CHECK, {
          spId: this.servicePartnerId,
          period,
          from,
          to,
        })
        .then((response) => {
          if (response.data.status) {
            this.period = period;
            this.mixpanelTrack({
              event: `combo_check_filter_${period}`,
            });
            this.setCombos();
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setQuery(query) {
      this.searchQuery = query;
    },
    setSelectedCombos(combos) {
      this.selectedCombos = [];
      this.showComboActions = false;
      this.markableForPlating = true;
      this.markableForSealing = true;
      this.markableForSleeving = true;
      combos.forEach((combo) => {
        this.markableForPlating = this.markableForPlating && !combo.plated;
        this.markableForSealing =
          this.markableForSealing && combo.plated && !combo.sealed;
        this.markableForSleeving =
          this.markableForSleeving &&
          combo.plated &&
          combo.sealed &&
          !combo.sleeved;
        this.selectedCombos.push(...combo.csp_ids);
        this.showComboActions = true;
      });
    },
    setSelectedCombo({ combo, action }) {
      this.selectedCombos = [];
      this.selectedCombos.push(...combo.csp_ids);

      switch (action) {
        case "plated":
          this.markFunction = "plated";
          this.markSelectedCombo("plated");
          break;
        case "sealed":
          this.markFunction = "sealed";
          combo.plated
            ? this.markSelectedCombo("sealed")
            : this.$message.error("You have to plate this combo");
          break;
        case "sleeved":
          this.markFunction = "sleeved";
          combo.plated && combo.sealed
            ? this.markSelectedCombo("sleeved")
            : this.$message.error("You have to plate and seal this combo");
          break;
        default:
          break;
      }
    },
    markSelectedCombo(action) {
      this.mixpanelTrack({
        event: `combo_check_single_${action}_mark`,
      });
      this.$nextTick(() => {
        this.$refs.markCombos.markOrders();
      });
    },

    setCombos() {
      const mealItems = this.$store.getters.combo_check.data;
      this.pageData = this.sortList(formatItems(mealItems, "combo"), "name");
      this.pageDataTime = new Date();
    },
    updateCombos() {
      this.loading = true;

      const markFunction = this.markFunction;
      const { full_name: name } = this.$store.getters.user;
      const time = new Date();

      this.pageData.forEach((combo, index) => {
        if (this.selectedCombos.includes(combo.csp_ids[0])) {
          this.pageData[index][`${markFunction}`] = true;
          this.pageData[index][`${markFunction}_at`] = time;
          this.pageData[index][`${markFunction}_by`] = name;
        }
      });

      this.mixpanelTrack({
        event: `combo_check_multiple_${markFunction}_mark`,
      });

      setTimeout(() => {
        this.unmarkCombos();
        this.selectedCombos = [];
        this.showComboActions = false;
        this.loading = false;
      }, 200);
    },
    unmarkCombos() {
      this.markAsPlated = false;
      this.markAsSealed = false;
      this.markAsSleeved = false;
    },
    viewHistory() {
      this.$router.push({ name: "combo-check.history" });
      this.mixpanelTrack({
        event: "combo_check_view_history",
      });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getCombos({ period: this.period });
    },
  },
};
</script>
