<template>
  <eden-filter :width="270" :properties.sync="properties" :disabled="disabled" :loading="loading" @open="getData"
    @reset="reset" @filter="filter" />
</template>

<script>
import * as actions from "@/store/action-types";
export default {
  name: "ServiceOperationsFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        status: {
          key: "filter[one-time]",
          label: "Order Type",
          type: "list",
          options: [
            {
              label: "One-time Orders",
              value: "one-time",
            },
            {
              label: "Same Day Delivery",
              value: "same-day-delivery",
            },
            {
              label: "Subscription",
              value: "subscription",
            },
          ],
          value: [],
        },
        fulfillment_center: {
          key: "filter[fulfillment_center_id]",
          label: "MFC",
          type: "list-multiple",
          options: [],
          value: [],
          searchable: true,
        },
        order_date: {
          key: ["from", "to"],
          label: "Order Date",
          type: "daterange",
          value: [],
        },
      },
    };
  },
  computed: {
    centers() {
      return this.$store.getters.micro_fulfillment_list;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.getCenters();
    },
    getCenters() {
      if (this.properties.fulfillment_center.options.length) {
        this.loading = false;
        return;
      }

      this.centers.length
        ? this.setCentersOptions()
        : this.$store.dispatch(actions.GET_MICROFULFILLMENT_LIST).then(() => {
          this.setCentersOptions();
        });
    },
    setCentersOptions() {
      this.properties.fulfillment_center.options = this.centers.map((center) => {
        return {
          label: this.formatName(center.name),
          value: center.id,
        };
      });
      this.loading = false;
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });

      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
