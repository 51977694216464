<template>
  <div>
    <div v-if="!loading && period === 'today'">
      <el-button
        v-if="showButton"
        type="success"
        :disabled="disabled"
        @click="showMarkStoveOff = true"
        >Mark Stove Off</el-button
      >
      <div v-else-if="stoveOff.marker" class="stove-off">
        <span> Stove Off Time: {{ formatTime(stoveOff.time) }}</span>
        <span>Marked by {{ stoveOff.marker }}</span>
      </div>
      <div v-else></div>
    </div>
    <div v-else></div>
    <eden-confirm-dialog
      title="Mark Stove Off"
      button-text="Mark"
      button-type="success"
      :show.sync="showMarkStoveOff"
      @confirm="markStoveOff"
    >
      <p class="font-sm">
        Proceed to mark Stove Off at
        <strong class="text-grey-primary">{{ formatTime("now") }} </strong>?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import combocheck from "@/requests/stewards/combocheck";

export default {
  name: "StoveOff",
  props: {
    period: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      showMarkStoveOff: false,
      stoving: false,
      stoveOff: {
        status: false,
        time: null,
        marker: "",
      },
    };
  },
  computed: {
    userId() {
      return this.$store.getters.user.logged_in_userId;
    },
    showButton() {
      return (
        !this.stoveOff.status &&
        this.allowAccessFor(["superadmin", "admin", "operations", "kitchen"])
      );
    },
  },
  created() {
    this.loading = true;
    this.getStoveOff();
  },
  methods: {
    getStoveOff() {
      const date = this.formatDate(new Date(), "y-m-d");

      combocheck
        .stoved(date)
        .then((response) => {
          const { data, status } = response.data;
          if (status) {
            if (data.length) {
              const { stove_off_time, chef } = data[0];
              this.setStoveOff(stove_off_time, true, chef.name);
            }
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    markStoveOff() {
      const payload = {
        chef_id: this.userId,
        date: this.formatDate(new Date(), "y-m-d"),
        stove_off_time: this.formatTime("now", "24h"),
      };
      combocheck
        .stove(payload)
        .then((response) => {
          const { data, status, message } = response.data;
          if (status) {
            this.mixpanelTrack({
              event: `stove_off`,
            });
            const { stove_off_time, chef } = data;
            this.setStoveOff(stove_off_time, true, chef.name);
            this.showMarkStoveOff = false;
            this.$message.success(message);
          } else {
            this.$message.error(response.data.message);
            this.showMarkStoveOff = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    setStoveOff(time, status, marker) {
      this.stoveOff.time = time;
      this.stoveOff.status = status;
      this.stoveOff.marker = marker;
    },
  },
};
</script>

<style lang="scss" scoped>
.stove-off {
  margin-right: 10px;

  span {
    display: block;
    font-size: 0.92rem;

    &:last-child {
      margin-top: 5px;
      color: #4b6358;
      font-size: 0.8rem;
    }
  }
}
</style>
