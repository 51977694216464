<template>
  <eden-confirm-dialog
    :title="`Mark as ${formatText(markFunction)}`"
    :button-text="'Mark'"
    :button-type="'success'"
    :show.sync="shouldShow"
    :close-on-click-modal="false"
    :button-status="loading"
    @confirm="markOrders"
    @cancel="unmarkOrders"
  >
    <p>
      Are you sure you want to mark selected combos as
      <span class="text-grey-primary text-bold">{{
        formatText(markFunction)
      }}</span>
      ?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import combocheck from "@/requests/stewards/combocheck";

export default {
  name: "MarkCombos",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    servicePartnerId: {
      type: [String, Number],
      default: "",
    },
    markFunction: {
      type: String,
      default: "plating",
    },
    combos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    userId() {
      return this.$store.getters.user.logged_in_userId;
    },
  },
  methods: {
    markOrders() {
      this.loading = true;
      const payload = {
        steward_id: this.userId,
        combo_ids: this.combos,
        combo_status: this.markFunction,
      };

      combocheck
        .mark(payload)
        .then((response) => {
          this.loading = false;
          if (response.data.status) {
            this.$emit("mark");
            this.$message.success(response.data.message);
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.shouldShow = false;
          this.$message.error(error.response.data.message);
        });
    },
    unmarkOrders() {
      this.$emit("unmark");
      this.shouldShow = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
